import { useEffect, useState, useContext, createContext } from 'react'
import { apiCall } from '@utils'
import { WorkspaceContext } from './Workspace'
import { UserContext } from './User'

export const RolesContext = createContext()

export const RolesProvider = ({ children }) => {
  const [roles, setRoles] = useState(null)
  const { workspace_id } = useContext(WorkspaceContext)
  const { user, setPermissions, loadingUser } = useContext(UserContext)

  const getRoles = async () => {
    const result = await apiCall(`${workspace_id}/role`)
    const userWorkspace = user.workspaces.find((w) => w.workspace_id === workspace_id)
    const userRole = result.body.find((r) => r.id === userWorkspace.role_id)
    setPermissions(userRole.topics)
    setRoles(result.body)
  }

  useEffect(() => {
    if (workspace_id && !loadingUser) getRoles()
  }, [workspace_id, loadingUser])

  return <RolesContext.Provider value={{ roles, setRoles }}>{children}</RolesContext.Provider>
}
